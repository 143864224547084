import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { OnlineProduct, ProductAttribute } from '../../interfaces/product';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { QuickviewService } from '../../services/quickview.service';
import { RootService } from '../../services/root.service';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit, OnDestroy, OnChanges {
    private destroy$: Subject<void> = new Subject();

    @Input() product: OnlineProduct;
    @Input() layout: 'grid-sm'|'grid-nl'|'grid-lg'|'list'|'horizontal'|null = null;
    @Input() hideBrand = false;
    @Input() isSlider = false; 

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    showingQuickview = false;
    featuredAttributes: ProductAttribute[] = [];
    userInfo:any = {};
    isMyProduct:boolean;
    sellAt:number = 45;

    constructor(
        private cd: ChangeDetectorRef,
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService,
        public compare: CompareService,
        public quickview: QuickviewService,
        public currency: CurrencyService,
        private userService:UserService,
        private router:Router
    ) { }

    ngOnInit(): void 
    { 
        this.userService.onUserInfoChanged$.pipe(takeUntil(this.destroy$)).subscribe(res =>
        { 
            this.isMyProduct = false; 
            this.userInfo = res; 
           
            if(this.userInfo !== null && this.userInfo.Id && this.userInfo.Id === this.product.SUID)
            { 
                this.isMyProduct = true; 
            }
            this.cd.markForCheck();
        });

        this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.markForCheck();
        });
    }

    ngOnDestroy(): void 
    {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void 
    {
        if ('product' in changes) {
          //  this.featuredAttributes = !this.product ? [] : this.product.attributes.filter(x => x.featured);
        }
    }
 
    addToCart(): void 
    {
        if (this.addingToCart) 
        {
            return;
        }

        this.addingToCart = true;
        this.quickview.show(this.product).subscribe({
            complete: () => {
                this.addingToCart = false;
                this.cd.markForCheck();
            }
        });

        // if (this.addingToCart) 
        // {
        //     return;
        // }
        // this.addingToCart = true; 

       
        // this.cart.add(this.product, 1, this.product.OptionList[0].items[0]).subscribe({
        //     complete: () => 
        //     {
        //         this.addingToCart = false;
        //         this.cd.markForCheck(); 
        //     }
        // });
    }

    addToWishlist(): void 
    {
        if (this.addingToWishlist) {
            return;
        }

        this.addingToWishlist = true;
        this.wishlist.add(this.product).subscribe({
            complete: () => {
                this.addingToWishlist = false;
                this.cd.markForCheck();
            }
        });
    }

    addToCompare(): void 
    {
        if (this.addingToCompare) {
            return;
        }

        this.addingToCompare = true;
        this.compare.add(this.product).subscribe({
            complete: () => {
                this.addingToCompare = false;
                this.cd.markForCheck();
            }
        });
    }

    showQuickview(): void 
    {
        if (this.showingQuickview) 
        {
            return;
        }

        this.showingQuickview = true;
        this.quickview.show(this.product).subscribe({
            complete: () => {
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    } 

    editProduct()
    {
        // this.quickview.showAddEditProduct(this.product).subscribe({
        //     complete: () => {
        //         this.showingQuickview = false;
        //         this.cd.markForCheck();
        //     }
        // });

        this.router.navigate(['/account/productpublish/'+this.product.Slug]);
    }

    getFormattedPrice() {
        const price = this.product.Price.toFixed(2);  
        const [whole, fraction] = price.split('.');  
        return { whole, fraction };
    }
}

