<div class="site-header">
    <app-header-topbar></app-header-topbar>

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo">
            <a routerLink="/"> 
                 <img width="100%" height="100%" src="assets/images/logos/1.png" alt="IzzLifestyle"/> 
            </a>
        </div>
        <div class="site-header__search">
            <app-search location="header"></app-search>
        </div>
        <div class="site-header__phone">
            <!-- <div class="site-header__phone-title">Customer Service</div> -->
            <!-- <div class="site-header__phone-number">{{ store.primaryPhone }}</div> -->
             <a class="btn btn-xs btn-secondary cnt-btn" href="/site/contact-us">
                <app-icon style="fill: #f0f0f0;" name="support-call-green" size="16"></app-icon>
                <span>Customer Support</span>
             </a>
        </div>
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav
            [departments]="layout === 'classic'"
            [logo]="layout === 'compact'"
            [search]="layout === 'compact'"
            stickyMode="pullToShow"
        ></app-header-nav>
    </div>
</div>
