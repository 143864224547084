import { Component, OnDestroy, OnInit } from '@angular/core'; 
import { Brand } from '../../shared/interfaces/brand';
import { Observable, Subject, merge, of } from 'rxjs';
import { ShopService } from '../../shared/api/shop.service';
import { OnlineProduct } from '../../shared/interfaces/product';
import { Category } from '../../shared/interfaces/category';
import { BlockHeaderGroup } from '../../shared/interfaces/block-header-group';
import { takeUntil, tap } from 'rxjs/operators';
import { RootService } from 'src/app/shared/services/root.service';
import { Post } from 'src/app/shared/interfaces/post';
import { BlogService } from 'src/app/shared/api/blog.service'; 

interface ProductsCarouselGroup extends BlockHeaderGroup {
    products$: Observable<OnlineProduct[]>;
}

interface ProductsCarouselData {
    abort$: Subject<void>;
    loading: boolean;
    products: OnlineProduct[];
    groups: ProductsCarouselGroup[];
}

@Component({
    selector: 'app-home',
    templateUrl: './page-home-one.component.html',
    styleUrls: ['./page-home-one.component.scss']
})
export class PageHomeOneComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>(); 
    brands$: Observable<Brand[]>;
    popularCategories$: Observable<Category[]>; 
    columnTopRated$: Observable<OnlineProduct[]>; 
    columnLatestProducts: any[] = []; 
    posts$:Observable<Post[]>;; 
    featuredProducts: ProductsCarouselData;
    latestProducts: ProductsCarouselData; 

    constructor(
        private shop: ShopService,
        private root: RootService,
        private blogS:BlogService
    ) 
    {   
        this.root.setmeta("Buy Anything You Need For Your Modern LifeStyle", {
            og_title:"Shop Trendy Home Decor, Gadgets, Toys And Any Lifestyle Products | IzzLifeStyle",
            og_description:"IzzLifeStyle offers a seamless shopping experience with trendy fashion, the latest gadgets, and unique lifestyle products. Explore our curated collections to elevate your style and make life easier, Premium Products And Free Shipping.",
            og_image:null,
            og_type:"product.group",
            og_url:"https://www.izzlifestyle.com"
        } );

        this.root.insertHomeSchema(); 
    }

    ngOnInit(): void 
    {   
        this.posts$ = this.blogS.getBlogList('','');
        //this.root.createLinkForCanonicalURL('https://www.izzlifestyle.com');
       // this.brands$ = this.shop.getBrandsForPage("home", 10); 
        //this.popularCategories$ = this.shop.getPopularCategories();    
        this.shop.getFeaturedProducts("feature").pipe(takeUntil(this.destroy$)).subscribe(res =>
        {
            this.columnLatestProducts = res;
        }); 

        this.featuredProducts = { 
            abort$: new Subject<void>(),
            loading: false,
            products: [],
            groups: [
                {
                    name: 'Latest',
                    current: true,
                    products$: this.shop.getLatestProducts(),
                }, 
                {
                    name: 'Super Saver',
                    current: false,
                    products$: this.shop.getFeaturedProducts('saver', 5),
                },
                {
                    name: 'Best Deals',
                    current: false,
                    products$: this.shop.getFeaturedProducts('saver', 5),
                }
            ],
        };
        this.groupChange(this.featuredProducts, this.featuredProducts.groups[0]);

        this.latestProducts = {
            abort$: new Subject<void>(),
            loading: false,
            products: [],
            groups: [
                {
                    name: 'Mega Offers',
                    current: true,
                    products$: this.shop.getFeaturedProducts("premium")
                },
                {
                    name: 'Luxury Deals',
                    current: false,
                    products$: this.shop.getFeaturedProducts("premium")
                } 
            ],
        };
        this.groupChange(this.latestProducts, this.latestProducts.groups[0]);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    groupChange(carousel: ProductsCarouselData, group: BlockHeaderGroup): void {
        carousel.loading = true;
        carousel.abort$.next();

        (group as ProductsCarouselGroup).products$.pipe(
            tap(() => carousel.loading = false),
            takeUntil(merge(this.destroy$, carousel.abort$)),
        ).subscribe(x =>{ carousel.products = x;});
    }
}
