import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { IDBProduct, ProductOption } from '../../interfaces/product';
import { FormControl } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { RootService } from '../../services/root.service';
import { QuickviewService } from '../../services/quickview.service';
import { UserService } from '../../services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ShopService } from '../../api/shop.service';
import { Router } from '@angular/router'; 
import { Banner } from '../../interfaces/ad-banner'; 
import { CurrencyService } from '../../services/currency.service';

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, AfterViewInit
{
    @HostListener('unloaded')
    ngOnDestroy() {
        console.log('Items destroyed');
    } 

    @Input() layout: ProductLayout; 
    @Input() product: IDBProduct;
    @Input() isQuickView:boolean;

    adBanner:Banner = {adClient:'ca-pub-7547733261487112', adSlot:1179812238, adFormat:'auto', fullWidthResponsive:true};

    quantity: FormControl = new FormControl(1);
    private destroy$: Subject<void> = new Subject();
    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    isMyProduct:boolean;
    userInfo:any = {}; 
    choosenSize:string ;
    selectedOption:any;
    selectedPriceOption:ProductOption;
    selectedImages:any[];
    selectedMax:number;
    highlightFeatures:any = {item1:{name:"", icon:""}, item2:{name:"", icon:""}, item3:{name:"", icon:""}, item4:{name:"", icon:""}}
    show = false;
    sellAt = 45;
    dStartDate:any;
    dEndDate:any;
    isMobile = true

    @ViewChild('#ad') script: ElementRef;
    
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private cd: ChangeDetectorRef,
        private wishlist: WishlistService,
        private compare: CompareService,
        public quickview: QuickviewService,
        public root: RootService,
        private user:UserService,
        private toastr:ToastrService, 
        private router:Router,
        private shop:ShopService,
        public currency:CurrencyService
    ) 
    { 
        this.toastr.toastrConfig.preventDuplicates = true
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.cart.onLimit$.subscribe(res=>
        {
            if(res)
            { 
                if(res.MaxExceed && res.MaxExceed === true)
                {
                    this.toastr.warning(`Maximum quantity limit is ${res.MaxLimit}`);
                }
                else if(res && res.ID > 0)
                {
                    this.selectedPriceOption.Quantity -= this.quantity.value; 
                }
            }
        });   
        this.user.onTabChanged$.next("specification");
    }
    
    ngAfterViewInit(): void { 
        
    }

    ngOnInit(): void 
    {   
        this.user.onUserInfoChanged$.pipe(takeUntil(this.destroy$)).subscribe(res =>
        { 
            this.isMyProduct = false;
            
            this.userInfo = res; 
            if(this.userInfo !== null && this.userInfo.SID === this.product.SID)
            {
                this.isMyProduct = true; 
            }
            this.cd.markForCheck();
        }); 

        this.initProductOptions();  
        this.shop.setRecentView(this.product);
        const [startDate, endDate] = this.calculateDeliveryRange(this.product.MaxDeliveryDays);
        this.dStartDate = startDate
        this.dEndDate = endDate;
        this.shop.getpvcount(this.product.Slug).pipe(takeUntil(this.destroy$)).subscribe(res=>{

        });
    }

    initProductOptions()
    { 
        if(this.product.SizeList && this.product.SizeList.length > 0)
        {
            this.choosenSize = this.product.SizeList[0];
        }
 
        if(this.product.OptionList && this.product.OptionList.length > 0)
        {
            this.selectedOption = this.product.OptionList[0];

            if(this.selectedOption && this.selectedOption.items && this.selectedOption.items.length > 0)
            {
                this.selectedImages = this.selectedOption.items[0].ImageList;
                this.selectedPriceOption = this.selectedOption.items[0];
                this.selectedMax = this.selectedPriceOption.Quantity;
            } 
        }  
    }

    addToCart(): void 
    {  
        if (!this.addingToCart && this.product && this.quantity.value > 0) 
        {
            if(this.selectedPriceOption && this.selectedPriceOption.Quantity < this.quantity.value)
            { 
                this.toastr.warning(`Maximum quantity limit is ${this.selectedMax}`);
                return;
            }

            this.addingToCart = true; 

            this.cart.add(this.product, this.quantity.value, this.selectedPriceOption, this.isQuickView)
            .subscribe({
                complete: () => 
                {
                    this.addingToCart = false; 
                    // this.cart.load();
                } 
            });
        }
    }

    addToWishlist(): void 
    {
        if (this.addingToWishlist) {
            return;
        }

        this.addingToWishlist = true;

        this.wishlist.add(this.product).subscribe({
            complete: () => {
                this.addingToWishlist = false;
                this.cd.markForCheck();
            }
        }); 
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

          this.compare.add(this.product).subscribe({complete: () => this.addingToCompare = false});
        }
    }

    editProduct()
    {
        this.quickview.showAddEditProduct(this.product).subscribe({
            complete: () => {
                //this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }

    optionChoose(option:any)
    { 
        this.selectedOption = option;
        console.log(this.selectedOption)

        if(this.selectedOption && this.selectedOption.items && this.selectedOption.items.length > 0)
        {
            this.selectedImages = this.selectedOption.items[0].ImageList;
            this.selectedPriceOption = this.selectedOption.items[0];
            this.selectedMax = this.selectedPriceOption.Quantity;
        }
        console.log(this.selectedOption);
    }

    sizeChoose(option:ProductOption)
    {
        this.selectedPriceOption = option;
        this.selectedMax = this.selectedPriceOption.Quantity;
        console.log(this.selectedPriceOption);
    }

    scroll(id: string) 
    {
        this.user.onTabChanged$.next("reviews");
        let el = document.getElementById(id);
        el.scrollIntoView({behavior:"smooth"});
    } 

    calculateDeliveryRange(deliveryDate: number): [Date, Date] {
        const today = new Date();
        let startDays: number;
        let endDays: number;
     
        if (deliveryDate <= 3) {
            startDays = deliveryDate;
            endDays = deliveryDate;
        } 
        else if (deliveryDate === 10 || deliveryDate === 9) {
            startDays = 3;
            endDays = 8;
        } else if (deliveryDate <= 15 && deliveryDate >= 11) {
            startDays = 6;
            endDays = 12;
        } else if (deliveryDate === 7) {
            startDays = 3;
            endDays = 6;
        } else if (deliveryDate === 5) {
            startDays = 2;
            endDays = 5;
        } 
        else if (deliveryDate >= 15) {
            startDays = 10;
            endDays = deliveryDate;
        }
        else {
            startDays = 3;
            endDays = 3;
        }
     
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + endDays);

        const startDate = startDays === endDays ? null : new Date(today);
        if (startDate !== null) {
            startDate.setDate(today.getDate() + startDays);
        }

        return [startDate, endDate];
    }

    haveSameMonth(date1: Date, date2: Date): boolean {
        var res = date1.getMonth() === date2.getMonth(); 
        return res;
    }

    getWebpImageUrl(imageUrl: string): string {
        if (!imageUrl) return '';
        
        const fileName = imageUrl.split('/').pop();
        const regex = /\.(jpg|jpeg|png|bmp|tiff)$/i;
        
        if (fileName && regex.test(fileName)) {
            const webpFileName = fileName.replace(regex, '.webp');
            return imageUrl.replace(fileName, `webp/${webpFileName}`);
        }
        
        return imageUrl;
    } 

    toTitleCase(input: string): string {
        return input
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    getFormattedPrice() {
        const price = this.selectedPriceOption.Price.toFixed(2);  
        const [whole, fraction] = price.split('.');  
        return { whole, fraction };
    }
    
}
