<!-- .block-features -->
<div class="block block-features block-features--layout--{{ layout }}">
    <div class="container">
        <div class="block-features__list">
           
            <div class="block-features__item" >
                <div class="block-features__icon">
                    <app-icon name="fi-free-delivery-48" size="48"></app-icon>
                </div>
                <div class="block-features__content" (click)="router.navigate(['/products/'])">
                    <div class="block-features__title">Free Shipping</div>
                    <div class="block-features__subtitle">For orders from $20</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item" (click)="router.navigate(['/products/toprated'])">
                <div class="block-features__icon">
                    <app-icon name="fi-payment-security-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">100% Safety</div>
                    <div class="block-features__subtitle">Only secure payments</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item" (click)="router.navigate(['/products/latest'])">
                <div class="block-features__icon">
                    <app-icon name="new" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">Latest products</div>
                    <div class="block-features__subtitle">See all latest products</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item" (click)="router.navigate(['/products/sale'])">
                <div class="block-features__icon">
                    <app-icon name="fi-tag-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">Hot Offers</div>
                    <div class="block-features__subtitle">Discounts up to 90%</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-features / end -->
