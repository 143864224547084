<!-- .dropcart -->
<div [class]="{
    dropcart: true,
    'dropcart--style--dropdown': type === 'dropdown',
    'dropcart--style--offcanvas': type === 'offcanvas',
    'dropcart--open': state.isOpen$|async
}">
    <div *ngIf="type === 'offcanvas'" class="dropcart__backdrop" (click)="close()"></div>
    <div class="dropcart__body">
        <div *ngIf="type === 'offcanvas'" class="dropcart__header">
            <div class="dropcart__title">Shopping Cart</div>
            <button class="dropcart__close" type="button" (click)="close()">
                <app-icon name="cross-12" size="12"></app-icon>
            </button>
        </div>

        <div class="dropcart__empty" *ngIf="!(cart.items$|async).length">
            Your shopping cart is empty!
        </div>
        <ng-container *ngIf="(cart.items$|async).length">
            <div class="dropcart__products-list">
                <div *ngFor="let item of cart.items$|async" class="dropcart__product">
                    <div class="dropcart__product-image product-image">
                        <a
                            *ngIf="item.product.Images?.length"
                            class="product-image__body"
                            [routerLink]="root.product(item.product)"
                            (click)="closeMenu.emit()"
                        >
                            <img class="product-image__img" [src]="item.product.Images[0]" alt="">
                        </a>
                    </div>
                    <div class="dropcart__product-info">
                        <div class="dropcart__product-name">
                            <a [routerLink]="root.product(item.product)" (click)="closeMenu.emit()">{{ item.product.Name }}</a>
                        </div>

                        <ul *ngIf="item.option" class="dropcart__product-options">
                            <li>
                                <div>{{ "Variant" }}: {{ item.option.Variant }}</div>  
                                <div *ngIf="item.option.Size" style="margin-top: 3px;">{{ "Size" }}: {{ item.option.Size }}</div> 
                             
                                <!-- <span class="filter-color__check input-check-color"
                                        [ngClass]="['input-check-color--' + ('#000'|colorType)]"
                                        [style.color]="item.option.VariantValue ? item.option.VariantValue : 'green'" style="display: flex;"> 
                                        <span style="color: #999999;">{{ 'Size' }}:</span>
                                        <label class="input-check-color__body"> 
                                            <span class="input-check-color__box" style="width: 12px; height:12px; margin-top: 2px;margin-left: 6px;"></span> 
                                            <span class="input-check-color__stick"></span>
                                        </label>
                                </span> -->
                            </li>
                        </ul>
                        <div class="dropcart__product-meta">
                            <span class="dropcart__product-quantity">{{ item.quantity }}</span> ×
                            <span class="dropcart__product-price">{{ item.option.Price|currencyFormat }}</span>
                        </div>
                    </div>

                    <button type="button" class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" (click)="remove(item)" [ngClass]="{'btn-loading': removedItems.includes(item)}">
                        <app-icon name="cross-10" size="10"></app-icon>
                    </button>
                </div>
            </div>

            <div class="dropcart__totals">
                <table>
                    <tr *ngIf="(cart.totals$|async).length">
                        <th>Subtotal</th>
                        <td>{{ cart.subtotal$|async|currencyFormat }}</td>
                    </tr>
                    <tr *ngFor="let total of cart.totals$|async">
                        <th>{{ total.title }}</th>
                        <td>{{ total.price|currencyFormat  }}</td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <td>{{ cart.total$|async|currencyFormat }}</td>
                    </tr>
                </table>
            </div>

            <div class="dropcart__buttons">
                <a class="btn btn-secondary" [routerLink]="root.cart()" (click)="close()">View Cart</a>
                <a class="btn btn-primary" [routerLink]="root.checkout()" (click)="close()">Checkout</a>
            </div>
        </ng-container>
    </div>
</div>
<!-- .dropcart / end -->
